.dark-blue {
    color: #003c6b;
}

.text-left {
    text-align: left !important;
}

.hero {
    height: calc(100vh - 6rem);
    align-items: center;
    margin-top: 3.7rem;
}

.title {
    text-align: left;
}

.second-title {
    color: #fff;
    opacity: .7;
    font-size: 1rem !important;
    font-weight: 400 !important;
    text-align: left;
    margin-bottom: 1rem;
    line-height: 1.5rem;
}

.side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    gap: .8rem;
    margin-bottom: 5rem;
}

.left-side {
    animation: fadeIn 1s ease-in-out;
}

.title {
    text-align: left;
}

.side > img {
    width: 80%;
    height: auto;
    animation: moveUpDown 2s infinite ease-in-out;
}

.services {
    background: #7ac9fa;
}

.card {
    background: var(--color-dark-blue) !important;
    color: #fff !important;
    border-radius: .5rem !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    transition: all .1s ease-in-out;
    text-decoration: none;
}

.card:hover {
    transform: translateY(-.1rem);
    cursor: pointer;
}

.cardText {
    color: #eee;
    text-align: left !important;
}

.card > h3 {
    text-align: left !important;
    width: 100%;
}

.card > img {
    margin-bottom: 2rem;
}

.card > a {
    margin-top: auto !important;
    color: #ff99bf !important;
    font-weight: 600;
}

.card > p {
    font-size: .9rem;
}

h2 {
    font-weight: 600 !important;
    font-size: 2.5rem !important;
}

.subtitle {
    position: relative;
    display: inline-block; /* Needed so the pseudo-element doesn't stretch the full width of the container */
}

.subtitle::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem; /* Adjust this value if you want the line closer/further from the text */
    width: 100%;
    height: 0.5rem;
    background-color: #ff99bf;
    border-radius: .5rem;
}

.portfolio {
    background: #003c6b;
}

.left-title {
    color: #fff;
    text-align: left;
}

.portfolio-img {
    border-radius: .5rem;
    width: 100%;
    transition: all .1s ease-in-out;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.portfolio-img:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.portfolio-text {
    text-align: left;
}
.portfolio-text a {
    color: #ff99bf;
    font-weight: 600;
}

.portfolio-title {
    color: #f5f5f5;
}

.portfolio-description {
    color: #eee;
}

.figures {
    background-color: var(--color-dark-blue);
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000033' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    min-height: 100px;
}

.figures-wrap {
    display: flex;
    gap: 10rem;
    justify-content: center;
    color: #fff;
}

.figure-title {
    font-size: 4rem !important;
}

.figure p {
    opacity: .7;
    margin-block-end: 0;
}

.figure a {
    color: #ff99bf;
    font-weight: 600;
    text-decoration: none;
}

.figure a:hover {
    text-decoration: underline;
}

.middle-item {
    flex-direction: row-reverse;
}

.portfolio-item {
    padding-bottom: 3rem !important;
}

@media (max-width: 768px) {
    .figures-wrap {
        gap: 2rem; 
        flex-direction: column;
    }

    .figure-title {
        font-size: 3rem !important;
    }
}

@media screen and (max-width: 992px) {
    .portfolio-item, .middle-item {
        flex-direction: column;
    }

    .portfolio-title {
        margin-top: 1rem;
    }

    .hero {
        margin-top: 0;
    }
}