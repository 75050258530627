.header {
    display: flex;
    gap: 10rem;
}
.header .btn-wrap {
    margin-bottom: 5rem;
}

.steam-wrap .intro-text {
    margin: 0 !important;
    padding-bottom: 0rem;
}

.steam-wrap .content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    align-items: start;
    margin-top: 5rem;
}

.steam-wrap .content h4 {
    color: #fff;
    width: 20rem !important;
    text-align: left;
    text-wrap: nowrap;
}

.steam-wrap .content p {
    text-align: left;
    color: #ccc;
    line-height: 2rem !important;
}

.cases h3 {
    color: #fff;
    margin-block-end: 2rem;
}

@media screen and (max-width: 992px) {
    .steam-wrap .content {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .title {
        text-align: left !important;
        padding-top: 1rem !important;
        margin-top: 1rem !important;
    }

    .header {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .header div, .header img {
        order: 1;
    }

    .header img {
        order: 0; /* This will make the image come first */
        height: 5rem;
        width: 5rem;
    }
}