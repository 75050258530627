.first-step {
    background: var(--color-dark-blue);
}

.first-step h2 {
    color: #fff;
    text-align: left !important;
    margin-block-end: 1rem !important;
    margin-top: 3rem;
}

.first-step .intro-text {
    padding-bottom: 1rem;
}

.first-step ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    align-items: first baseline;
}

.first-step ul li {
    list-style: none;
    margin-bottom: 1rem;
    color: #fff;
}

.first-step img {
    animation: moveUpDown 2s infinite ease-in-out;

}

.first-wrap {
    display: flex;
    gap: 10rem;
}

.code-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-wrap {
    text-align: left;
}

@media screen and (max-width: 992px) {
    .first-wrap {
        flex-direction: column;
        gap: 1rem;
    }

    .first-wrap .code-img-wrap {
        margin-top: 2rem;
    }

    .first-wrap .intro-text {
        order: 1;
    }

    .btn-wrap {
        text-align: center;
    }
    
}