.consultation-form {
    position: fixed;
    right: 0;
    top: 0;
    max-width: 540px;
    width: 100%;
    height: 100vh;
    background-color: var(--color-dark-blue); 
    padding: 20px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1000; 
    animation: slideIn 0.5s ease-out;
}

.close {
    font-size: 1.5rem;
    float: right;
}

.close:hover {
    cursor: pointer;
    color: var(--color-pink);
}

.consultation-form h3 {
    color: #fff;
    text-align: left;
    margin-top: 2rem;
    font-weight: 600;
}

.consultation-form .btn {
    padding: .5rem 1rem !important;
    font-size: 1rem !important;
    display: flex;
}

.consultation-form label {
    color: #fff;
    opacity: .7;
    display: flex;
    margin-bottom: 0;
    font-size: .8rem;
}

.consultation-form input, 
.consultation-form textarea {
    padding: .5rem 1rem;
    font-size: .9rem;
}

.invalid-feedback {
    color: var(--color-pink) !important;
    text-align: left !important;
}

.consultation-form .form-check-input {
    padding: .5rem;
    float: none;
    margin-top: 0;
    margin-left: 0;
}

.form-check {
    display: flex !important;
    gap: .5rem;
    align-items: center;
    padding: 0;
}

.form-check-input:checked {
    background-color: var(--color-blue) !important;
    border-color: var(--color-blue) !important;
}

.consultation-form a {
    color: var(--color-pink) !important;
    margin-bottom: 0;
    text-decoration: none;
}

.consultation-form a:hover {
    color: var(--color-pink) !important;
    text-decoration: underline !important;
}

.form-check-label {
    display: inline-block !important;
    text-align: left;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

.consultation-form.slide-out {
    animation: slideOut .25s ease-out forwards;
}

.success-message {
    color: #fff;
}

.error-message {
    color: var(--color-pink);
}

.social__media__instructions {
    display: flex;
    text-align: left;
    gap: 1rem;
    color: var(--text-white);
    margin-top: 1.5rem;
}