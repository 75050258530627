#contact {
    background: #7ac9fa;
}

h5 {
    font-weight: 600 !important;
    text-transform: uppercase;
}

.copyright-text {
    color: #111;
    margin-top: 2rem !important;
    font-size: .8rem;
}

.socials {
    display: flex;
    gap: 1rem;
}