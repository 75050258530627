html {
  background: #000033;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  padding: 2rem;
}

:root {
  --text-white: #f5f5f5;
  --color-pink: #ff99bf;
  --color-blue: #7ac9fa;
  --color-dark-blue: #156cad;
  --opacity: .7;
  --bs-form-invalid-color: #ff99bf !important;
  --bs-form-invalid-border-color: #ff99bf !important;
}

h1 {
  color: #f5f5f5 !important;
}

.text-pink {
  color: #ff99bf !important;
}

.box-3d {
  box-shadow: 
    0px 1px 2px rgba(0,0,0,0.2), 
    0px 2px 4px rgba(0,0,0,0.2), 
    0px 4px 8px rgba(0,0,0,0.2), 
    0px 8px 16px rgba(0,0,0,0.2),
    0px 16px 32px rgba(0,0,0,0.2),
    0px 32px 64px rgba(0,0,0,0.2) !important;
}

/* .body-shadow {
  position: relative ;
  z-index: 1;
} */

.body-shadow::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0 ;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 2;
}


/* ANIMATIONS */
.fly-left {
  animation: flyInFromLeft .5s ease-in-out;
}

.fly-right {
  animation: flyInFromRight .5s ease-in-out;
}

.fly-bottom {
  animation: flyInFromBotton .5s ease-in-out;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.intro-text {
  margin-top: .5rem;
  padding-bottom: 5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  opacity: .7 !important;
}

/* ANIMS */
@keyframes flyInFromRight {
  from {
      transform: translateX(50%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes flyInFromLeft {
  from {
      transform: translateX(-20%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes flyInFromBotton {
  from {
      transform: translateY(20%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes moveUpDown {
0%, 100% {
  transform: translateY(-.5rem);
}
50% {
  transform: translateY(.5rem);
}
}