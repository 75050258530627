
.cases .card {
    padding: 0 !important;
}

.cases .card-img-top {
    margin-bottom: .3rem !important;
}
.cases .card-title {
    text-align: left;
    text-transform: none;
    padding: .5rem;
}

.cases .card-body {
    display: flex;
    flex-direction: column;
}

.cases .card-text {
    text-align: left;
    font-size: .9rem;
    color: #ccc;
    padding: 0 .5rem;
}

.technologies {
    text-align: left;
    opacity: .5;
    padding: 0 .5rem;
    font-size: .8rem;
}

.visit-link {
    color: #fff !important;
}

.visit-link:hover {
    color: #ff99bf !important;
}

.link-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 .5rem;
    color: #fff;
    margin-top: auto !important;
    overflow-wrap: break-word;
    word-break: break-all;
}

.link-icon {
    text-decoration: none !important;
    margin-left: .3rem;
}