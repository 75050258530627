.btn {
    background-color: #ff99bf !important;
    padding: 1rem 2.5rem !important;
    font-weight: 600 !important;
    color: #111 !important;
    transition: all .1s !important;
    box-sizing: border-box; /* Include padding and border in an element's total width and height */
    border-radius: .5rem !important;
    font-size: 1.2rem !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
}
  
button:hover {
  transform: translateY(-.2rem) !important;
}