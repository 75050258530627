@media (max-width: 991px) {
  .side {
    align-items: center;
    text-align: center !important;
    margin-top: 0 !important;
  }

  .side > img {
    /* height: 20rem; */
    object-fit: contain;
    margin-top: 2rem;
  }

  .hero {
    height: auto;
  }

  .title {
    margin-top: 6rem;
    text-align: center !important;
  }

  .card {
    padding: 2rem;
    margin: 1rem .5rem;
  }

  .second-title {
    text-align: center !important;
  }
}

@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
    .title {
        font-size: 2.4rem;
    }
  }
  
  @media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
    .title {
        font-size: 2.4rem;
    }
  }
  
  @media (min-width: 992px) { /* Large devices (desktops, 992px and up) */
    .title {
        font-size: 2.4rem;
    }

    .card {
      padding: 3rem 2rem;
    }
  }
  
  @media (min-width: 1400px) { /* Extra large devices (large desktops, 1400px and up) */
    .title {
        font-size: 3rem;
    }

    .card {
      padding: 3rem 4rem;
    }
  }