nav img {
    transition: .2s;
}

nav img:hover {
    cursor: pointer;
    transform: translateY(-.2rem);
}

nav > .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul > li > a {
    margin-left: 2rem;
    color: #ccc !important;
    text-decoration: none;
    line-height: 2rem;
}

ul > li > a:hover {
    color: #fff !important;
    text-decoration: none;
}

.navbar-toggler {
    padding: .5rem 1rem !important;
}

.navbar .contact-button {
    padding: .5rem 1rem !important;
    font-size: 1rem !important;
    margin-left: 2rem;
}

.nav-link {
	position: relative;
}

/* Base style for pill-shaped form */
.nav-link::after {
	content: "";
	display: block;
	width: 0; /* Initially hidden */
	height: 5px;
	background-color: var(--color-pink);
	position: absolute;
	bottom: .5rem;
	left: 0;
	border-radius: .5rem;
	transition: width .3s ease;
}

.nav-link:hover::after {
	width: 100%;
	background-color: var(--color-pink);
}

.active .nav-link::after,
.nav-link:active::after,
.nav-link:focus::after {
	width: 100%;
	background-color: var(--color-pink);
}

.active {
    color: #fff !important;
    opacity: 1 !important;
}

.bar {
    left: 0px;
    border-radius: 5pt;
    display: block;
    position: relative;
    transform-origin: right;
    height: 3pt !important;
    width: 28pt;
    background-color: var(--color-pink);
    transition: all 0.3s ease;
}

.burger-box{
    width: 30pt;
    height: 28pt;
    padding-top: 5pt;
    margin-left: auto;
}

.burger-box:hover {
    cursor: pointer;
}

.bar1{
    top: 0pt;
}

.bar2{
    top: 8pt;
}

.bar3{
    top: 16pt;
}

.bar1, .bar3{
    transform-origin: right;
}

:root{
    --top-offset-after-animation: 1.29pt;
    --left-offset-after-animation: -4.5pt;
}

.active.bar1 {
	transform: rotate(-45deg);
	top: var(--top-offset-after-animation);
    left: var(--left-offset-after-animation);
}

.active.bar2 {
	opacity: 0% !important;
}

.active.bar3 {
	transform: rotate(45deg);
	top: calc(16pt - var(--top-offset-after-animation));
    left: var(--left-offset-after-animation);
}

@media screen and (max-width: 991px) {

    .nav-wrap {
        /* position: fixed; */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-dark);
        z-index: 1000;
        transition: all .3s ease;
    }

	.navbar-nav {
		height: 100vh;
	}

    .nav-wrap .container {
        padding-top: .5rem;
    }

    .navbar-nav {
        justify-content: flex-start;
        margin-top: 5rem;
    }

    .nav-item button {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 992px) {
	.burger-box {
		display: none;
	}
}